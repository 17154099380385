<template>
  <div class="services">
    <div class="header">
      <h3 class="sub-title">
        Услуги
      </h3>
      <div class="button-box">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              class="ma-2 white--text"
              @click="addService()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                left
                dark
              >
                mdi-plus
              </v-icon>
              Добавить
            </v-btn>
          </template>
          <span>Добавить новый параметр для поиска</span>
        </v-tooltip>
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="search()"
        >
        <v-icon
          left
          dark
        >
          mdi-magnify
        </v-icon>
          Поиск
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
     v-if="!load"
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>
    <table class="table table-white" v-else>
      <thead>
        <tr>
          <th
            v-for="th in header"
            :key="th"
            class="text-center">
            {{th}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(td, index) in items"
          :key="index"
        >
          <td class="text-center">
            <v-autocomplete
              :items="getServices"
              placeholder="Код или наименование услуги"
              v-model="td.code"
            ></v-autocomplete>
          </td>
          <td class="text-center">
            <v-icon color="red" size="16px" v-if="index >= 1"  @click="removeService(index)">
              mdi-trash-can-outline
            </v-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Services',
    data () {
      return {
        header: [
          'Услуга',
          'Действия',
        ],
        load: false,
        items: [
          {
            code: '',
          },
        ]
      }
    },
    computed : {
      ...mapGetters({
          getServices: 'lookups/getServices',
          getSMP: 'smp/getSMP'
      }),
    },
    methods: {
      ...mapActions({
        services: 'lookups/services',
        smpFilter: 'smp/smpFilter'
      }),
      ...mapMutations({
        smpSearch: 'smp/smpSearch',
      }),
      addService() {
        this.items.push({
          code: '',
        })
      },
      search() {
        this.smpSearch(true)
        this.smpFilter({
          serviceCodes: this.items.map(item => {
            return item.code
          })
        })
      },
      removeService(index) {
        this.items.splice(index, 1);
      }
    },
    mounted() {
      this.services().then(() => this.load = true)
      this.smpSearch(false)
    },
    destroyed() {
      this.smpFilter({})
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
